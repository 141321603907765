import React, { Component, useState, useEffect } from 'react';
import PropTypes, { func } from 'prop-types';
import { AppContext } from './createContext';
import createPersistedState from 'use-persisted-state';

const eventRecordsState = createPersistedState('eventRecordsPersisted');
const currentEventState = createPersistedState('currentEventPersisted');

import {
  useAsyncCombineSeq,
  useAsyncRun,
  useAsyncTask,
  useAsyncTaskDelay,
  useAsyncTaskFetch,
} from 'react-hooks-async';

const fetchEventHero = async ({ signal }, nid) => {
  const response = await fetch(
    `${process.env.GATSBY_DRUPAL_API_URL}/api/event/${nid}/view`,
    {
      signal,
    }
  );
  const data = await response.json();
  return data;
};

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.

export const AppProvider = props => {
  const { start, started, error, result } = useAsyncTask(fetchEventHero);
  const [currentEvent, setCurrentEvent] = currentEventState([]);
  const [currentRace, setCurrentRace] = useState({ currentRace: {} });
  const [eventRecords, setEventRecords] = eventRecordsState([]);

  return (
    <AppContext.Provider
      value={{
        start,
        started,
        error,
        result,
        currentEvent,
        setCurrentEvent,
        currentRace,
        setCurrentRace,
        eventRecords,
        setEventRecords,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
